import locale from "../config/i18n/diclina";
import i18nService from "./i18n.service";

const lang = i18nService.getActiveLanguage();

const i18nDiclinaService = {
  getDiclinaStatusGroupName: (statusGroup) => {
    try {
      return locale.STATUSGROUP[statusGroup][lang];
    } catch (e) {
      return statusGroup;
    }
  },
  getDiclinaStatusGroupKeys: (statusGroup) => {
    try {
      return Object.keys(locale.STATUSGROUP[statusGroup].STATUS);
    } catch (e) {
      return statusGroup;
    }
  },
  getDiclinaStatusName: (statusGroup, status) => {
    try {
      return locale.STATUSGROUP[statusGroup].STATUS[status][lang];
    } catch (e) {
      return statusGroup + " -> " + status;
    }
  },
  getDiclinaStatusStyle: (statusGroup, status) => {
    try {
      return locale.STATUSGROUP[statusGroup].STATUS[status].style;
    } catch (e) {
      return null;
    }
  },
  getDiclinaEditPermission: (statusGroup) => {
    return locale.STATUSGROUP[statusGroup].requiredPermission;
  },
};

export default i18nDiclinaService;
